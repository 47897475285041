import React from 'react';
import PropTypes from 'prop-types';

import LearnCard from './LearnCard';
import wordings from './wordings';

const LearnGallery = ({ i18n, siteId }) => {
  const locales = wordings(i18n, siteId);
  return (
    <section className="learning">
      <div className="learning__cont-cards">
        {locales.List.map((item) => (<LearnCard
          image={item.image}
          title={item.title}
          description={item.description}
          name={item.name}
          cta={item.cta_text}
          url={item.cta_url}
        />))}
      </div>
    </section>
  );
};

LearnGallery.propTypes = {
  siteId: PropTypes.string.isRequired,
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }).isRequired,
};

export default LearnGallery;
