import React, { useState } from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';
import { Image } from 'nordic/image';
import Modal from '@andes/modal';
import Thumbnail from '@andes/thumbnail';
import trackEvent from '../../../../utils/tracking';
import { TRACK_CASES, TRACK_MODAL, TRACK_VIEWS } from './tracks';

const Card = ({
  Link,
  logoImage,
  altImage,
  bgBrand,
  imageModal,
  ctaText,
  DeviceType,
  track,
}) => {
  const cdnLandingUrl = 'https://http2.mlstatic.com/frontend-assets/mshops-web-landing/';
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => {
    setIsOpen(false);
  };

  const cardClassName = classnames('cards-list-item', { [`cards-list-item--${bgBrand}`]: bgBrand });

  const VIEW_SHOP = 'VIEW_SHOP';
  const OPEN_SHOP = 'OPEN_SHOP';
  const HOME_PREVIEW_SHOP = 'HOME_PREVIEW_SHOP';

  const handleCard = () => {
    setIsOpen(true);
    if (DeviceType !== 'desktop') {
      trackEvent(TRACK_CASES(OPEN_SHOP, track, true));
    } else {
      trackEvent(TRACK_VIEWS(HOME_PREVIEW_SHOP, track));
      trackEvent(TRACK_CASES(VIEW_SHOP, track));
    }
  };

  const handleModal = () => {
    trackEvent(TRACK_VIEWS(HOME_PREVIEW_SHOP, track));
    trackEvent(TRACK_MODAL(OPEN_SHOP, track));
  };

  return (
    <>
      {DeviceType === 'desktop'
        ? (
          <div role="button" className={cardClassName} onClick={handleCard}>
            <div className="cards-list-item__card-cont">
              <Image src={logoImage} className="cards-list-item__card-logo" alt={altImage} lazyload="off" />
              <div className="displayed">
                <span className="cta-card">{ctaText}</span>
              </div>
            </div>
          </div>
        )
        : (
          <a
            href={Link}
            target="_blank"
            onMouseEnter={e => showButton(e)}
            onMouseLeave={e => hideButton(e)}
            onClick={() => trackEvent(TRACK_CASES(OPEN_SHOP, track, true))}
            className={cardClassName}
            rel="noopener noreferrer"
          >
            <div className="cards-list-item__card-cont cards-list-item__card-cont--mobile">
              <Image src={logoImage} className="cards-list-item__card-logo" alt={altImage} />
              <div className="displayed">
                <span className="cta-card">
                  {ctaText}
                </span>
              </div>
            </div>
          </a>
        )}
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        maxWidth="600"
        type="large"
        media={{
          asset: (
            <Thumbnail modifier="square" className="image-modal-thumbnail">
              <Image alt={altImage} src={`${cdnLandingUrl}landing30/${imageModal}`} className="image-modal" />
            </Thumbnail>
          ),
        }}
      >
        <div className="button-modal-cont">
          <a href={Link} onClick={handleModal} target="_blank" className="button-modal" rel="noreferrer">
            {ctaText}
          </a>
        </div>
      </Modal>
    </>
  );
};

Card.propTypes = {
  Link: PropTypes.string.isRequired,
  logoImage: PropTypes.string.isRequired,
  altImage: PropTypes.string.isRequired,
  bgBrand: PropTypes.string.isRequired,
  imageModal: PropTypes.string.isRequired,
  ctaText: PropTypes.string.isRequired,
  DeviceType: PropTypes.string.isRequired,
  track: PropTypes.string.isRequired,
};

export default Card;
