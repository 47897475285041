export default i18n => ({
  slide: [
    {
      title: 'Emanda',
      subtitle: 'Email Marketing',
      img: '/mlb/emanda.png',
      url_card: 'https://centraldeparceiros.mercadolivre.com.br/apps/emanda',
      track: 'EMANDA',
    },
    {
      title: 'Mailbiz',
      subtitle: 'Email Marketing',
      img: '/mlb/mailbiz.png',
      url_card: 'https://centraldeparceiros.mercadolivre.com.br/apps/mailbiz',
      track: 'MAILBIZ',
    },
    {
      title: 'Perfit',
      subtitle: 'Email Marketing',
      img: '/mla/perfit.png',
      url_card: 'https://centraldeparceiros.mercadolivre.com.br/apps/perfit-mshops-br',
      track: 'PERFIT',
    },
  ],
});
