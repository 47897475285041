/* eslint-disable global-require */
import React from 'react';
import { hydrate } from 'nordic/hydrate';

import I18n from 'nordic/i18n';
import I18nProvider from 'nordic/i18n/I18nProvider';
import ImageProvider from 'nordic/image/provider';
import { AndesProvider } from '@andes/context';
import '../pages/landing30/styles/landing30.desktop.scss';
import './polyfills';
import Landing30View from '../pages/landing30/components/Views/Landing30View';
import excludeErrorsNewrelic from '../utils/newRelic/errorHandler';

const {
  siteId,
  shopId,
  shopName,
  shopStatus,
  shopUrl,
  lowEnd,
  deviceType,
  company,
  translations,
  melidataPath,
  commonTrackData,
  viewName,
  trackers,
  pathName,
  isNewShop,
  imagePrefix,
  pricing,
  gtmId,
  csrfToken,
  queryParams,
  reqHeader,
  locale,
} = window.__PRELOADED_STATE__;

const i18n = new I18n({ translations });

excludeErrorsNewrelic();

// We decide which screen to show

hydrate(
  <I18nProvider i18n={i18n}>
    <ImageProvider prefix={imagePrefix}>
      <AndesProvider locale={locale}>
        <Landing30View
          translations={translations}
          siteId={siteId}
          shopId={shopId}
          shopName={shopName}
          shopUrl={shopUrl}
          shopStatus={shopStatus}
          melidataPath={melidataPath}
          commonTrackData={commonTrackData}
          lowEnd={lowEnd}
          deviceType={deviceType}
          company={company}
          viewName={viewName}
          pathName={pathName}
          trackers={trackers}
          isNewShop={isNewShop}
          pricing={pricing}
          gtmId={gtmId}
          csrfToken={csrfToken}
          queryParams={queryParams}
          reqHeader={reqHeader}
        />
      </AndesProvider>
    </ImageProvider>
  </I18nProvider>,
  document.getElementById('root-app'),
);
