import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Image from 'nordic/image';
import trackEvent from '../../../../../utils/tracking';
import { TRACK_PARTNERS } from '../tracks';

import IconCheck from '../../../assets/components/IconCheck';
import IconGroup from '../../../assets/components/IconGroup';
import IconStars from '../../../assets/components/IconStars';
import IconStarsWhite from '../../../assets/components/IconStarsWhite';
import IconTriangle from '../../../assets/components/IconTriangle';

const TabNumber = ({ card, mobile = false }) => (
  <div>
    <div className="tabs__card">
      <div className="tabs__card-head">
        <div className="tabs__card-content">
          <div className="tabs__card-title">{card.title}</div>
          {card.subtitle && (
            <div
              className="tabs__card-subtitle"
              dangerouslySetInnerHTML={{ __html: card.subtitle }}
            />
          )}

          {card.items && (
          <div className="tabs__card-items">
            {card.items.map((item) => (
              <div className="tabs__card-item">
                <IconCheck className="tabs__card-item-ico" />
                <div className="tabs__card-item-text">{item}</div>
              </div>
            ))}
          </div>
          )}

          {card.description && (
            <div className="tabs__card-description">{card.description}</div>
          )}
        </div>
        <div className="tabs__card-video">
          <iframe
            loading="lazy"
            src={card.video}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      </div>

      <div className="tabs__card-footer">
        {mobile ? (
          <>
            <div className="tabs__card-count-mobile">
              <div>
                <div className="tabs__card-count-mobile-icon-count">
                  <div className="tabs__card-count-mobile-icon">
                    <IconGroup />
                  </div>
                  <div className="tabs__card-count-mobile-count">
                    {card.count1Number}
                  </div>
                </div>
                <div className="tabs__card-count-mobile-description">
                  <strong>{card.count1Title}</strong>{' '}
                  {card.count1SubtitleMobile}
                </div>
              </div>
            </div>
            <div className="tabs__card-count-mobile--active">
              <div>
                <div className="tabs__card-count-mobile-icon-count">
                  <div className="tabs__card-count-mobile-icon">
                    <IconStarsWhite />
                  </div>
                  <div className="tabs__card-count-mobile-count">
                    {card.count2Number}
                  </div>
                </div>
                <div className="tabs__card-count-mobile-description">
                  {card.count2Subtitle} <strong>{card.count2Title}</strong>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="tabs__card-count">
            <div className="tabs__card-count-item">
              <div className="tabs__card-count-icon-number">
                <div className="tabs__card-count-item-icon">
                  <IconGroup />
                </div>
                <div className="tabs__card-count-item-number">
                  {card.count1Number}
                </div>
              </div>
              <div className="tabs__card-count-item-title">
                {card.count1Title}
              </div>
              <div className="tabs__card-count-item-subtitle">
                {card.count1Subtitle}
              </div>
            </div>
            <div className="tabs__card-count-divider" />
            <div className="tabs__card-count-item">
              <div className="tabs__card-count-icon-number">
                <div className="tabs__card-count-item-icon">
                  <IconStars />
                </div>
                <div className="tabs__card-count-item-number">
                  {card.count2Number}
                </div>
              </div>
              <div className="tabs__card-count-item-subtitle">
                {card.count2Subtitle}
              </div>
              <div className="tabs__card-count-item-title">
                {card.count2Title}
              </div>
            </div>
          </div>
        )}

        { card.experience && (
          <div className="tabs__card-experience">{card.experience}</div>
        )}

        <div className="tabs__card-experience-video">
          <iframe
            loading="lazy"
            src={card.video}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>

        <div className="tabs__card-alert">
          <Image src={card.alertIcon} alt="" />
          <div className="tabs_card-message">
            <span className="tabs_card-message-rectangle" />
            <div className={classnames(
              'tabs__card-alert-title',
              { 'tabs__card-alert-title--notborder': card.track === 'WORKSHOP' },
            )}
            >
              {card.alertText}
            </div>
            {card.track === 'BRIGADE' && <IconTriangle className="tabs__card-alert-triangle" />}
          </div>
          <a
            onClick={() => trackEvent(TRACK_PARTNERS(card.track))}
            href={card.alertBtnLink}
            type="button"
            className="tabs__card-alert-button"
          >
            {card.alertBtn}
          </a>
        </div>
      </div>
    </div>
  </div>
);

TabNumber.propTypes = {
  card: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    description: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.string),
    video: PropTypes.string,
    count1Number: PropTypes.string,
    count1Title: PropTypes.string,
    count1Subtitle: PropTypes.string,
    count2Number: PropTypes.string,
    count2Title: PropTypes.string,
    count2Subtitle: PropTypes.string,
    alertIcon: PropTypes.string,
    alertText: PropTypes.string,
    alertBtn: PropTypes.string,
    type: PropTypes.number,
    count1SubtitleMobile: PropTypes.string,
    experience: PropTypes.string,
    alertBtnLink: PropTypes.string,
    track: PropTypes.string,
  }).isRequired,
  mobile: PropTypes.bool,
};

export default TabNumber;
