// i18nLocalesExclusion:['pt-BR']

export default i18n => ({
  slide: [
    {
      studio_name: i18n.gettext('Estudio fotográfico {0}', 'Aselleration'),
      img: '/mla/aselleration.png',
      url_card: 'https://centrodepartners.mercadolibre.com.ar/solutions/fotografos/aselleraction',
      track: 'ASELLERATION',
    },
    {
      studio_name: i18n.gettext('Estudio fotográfico {0}', 'Teemstudios'),
      img: '/mla/teemstudios.png',
      url_card: 'https://teemstudios.com/',
      track: 'TEEMSTUDIOS',
    },
    {
      studio_name: i18n.gettext('Estudio fotográfico {0}', 'Lopetegui Fotos'),
      img: '/mla/lopetegui.png',
      url_card: 'https://www.instagram.com/lopeteguifoto/',
      track: 'LOPETEGUI',
    },
  ],
});
