// i18nLocalesExclusion:['pt-BR']

export default i18n => ({
  category_list: [
    {
      title: i18n.gettext('Configuraciones de tu Shop'),
      category: 'config',
      List: [
        {
          /* i18n-next-line except: ["pt-BR"] */
          title: i18n.gettext('Super Guia Mercado Shops'),
          button: i18n.gettext('Descargar'),
          button_url: 'https://mktdevzone.com/ML/download/ShopsAR_SuperGuia3.pdf',
          track: 'mshops_guide',
          image: 'mla/super_guia_mshops.jpg',
        },
        {
          title: i18n.gettext('Glosario digital: no te pierdas en el mundo del ecommerce'),
          button: i18n.gettext('Descargar'),
          button_url: 'https://bit.ly/ShopsAr_GlosarioDigital',
          track: 'digital_glossary',
          image: 'mla/glosario_digital.jpg',
        },
      ],
    },
    {
      title: i18n.gettext('Estrategias de Tráfico'),
      category: 'trafic',
      List: [
        {
          title: i18n.gettext('¿Cómo llevar tráfico a tu tienda?'),
          button: i18n.gettext('Descargar'),
          button_url: 'https://bit.ly/ShopsAR_Ebook_Trafico',
          track: 'getting_traffic',
          image: 'mla/trafico_tienda.jpg',
        },
        {
          title: i18n.gettext('Template RRSS'),
          button: i18n.gettext('Descargar'),
          button_url: 'https://bit.ly/ShopsAR_TemplateRRSS',
          track: 'rrss_templates',
          image: 'mla/template_rrss.jpg',
        },
        {
          title: i18n.gettext('eBook SEO'),
          button: i18n.gettext('Descargar'),
          button_url: 'https://bit.ly/ShopsAR_SEO ',
          track: 'seo_config',
          image: 'seo.jpg',
        },
      ],
    },
    {
      title: i18n.gettext('Fidelización y ventas'),
      category: 'sales',
      List: [
        {
          title: i18n.gettext('Planificación Estratégica'),
          button: i18n.gettext('Descargar'),
          button_url: 'https://bit.ly/ShopsAR_PlanificacionEstrategica3',
          track: 'strategic_planning',
          image: 'mla/planificacion_estrategica.jpg',
        },
        {
          title: i18n.gettext('El ABC del Email Marketing'),
          button: i18n.gettext('Descargar'),
          button_url: 'https://bit.ly/ShopsAR_Ebook_EmailMkt ',
          track: 'email_marketing_abc',
          image: 'mla/abc_email.jpg',
        },
        {
          title: i18n.gettext('Calendario fechas especiales 2023'),
          button: i18n.gettext('Descargar'),
          button_url: 'https://bit.ly/ShopsAR_Calendario2023',
          track: 'ecommerce_calendar',
          image: 'mla/calendario_fechas_especiales.jpg',
        },
        {
          title: i18n.gettext('Guía Hot Sale'),
          button: i18n.gettext('Descargar'),
          button_url: 'https://bit.ly/ShopsAR_HotSaleGuia3 ',
          track: 'hotsale_guide',
          image: 'mla/guia_hot_sale.jpg',
        },
        {
          title: i18n.gettext('Check List Hot Sale / Cyber Day'),
          button: i18n.gettext('Descargar'),
          button_url: 'https://bit.ly/ShopsAR_PlannerCM',
          track: 'checklist_hotsale_cyberday',
          image: 'mla/checklist_hs_cd.jpg',
        },
        {
          title: i18n.gettext('Guía Cyber Monday'),
          button: i18n.gettext('Descargar'),
          button_url: 'https://bit.ly/ShopsAR_GuiaCM',
          track: 'cybermonday_guide',
          image: 'mla/guia_cyber_monday.jpg',
        },
        {
          title: i18n.gettext('Planer Cyber Monday'),
          button: i18n.gettext('Descargar'),
          button_url: 'https://bit.ly/ShopsAR_PlannerCM',
          track: 'planner_cybermonday',
          image: 'mla/planer_cyber_monday.jpg',
        },
        {
          title: i18n.gettext('Fidelización'),
          button: i18n.gettext('Descargar'),
          button_url: 'https://mktdevzone.com/MercadoShops/download/ebook-fidelizacion-ar.pdf',
          track: 'loyalty_context',
          image: 'mla/fidelizacion.jpg',
        },
      ],
    },
    {
      title: i18n.gettext('Personalización'),
      category: 'custom',
      List: [
        {
          title: i18n.gettext('Banners descargables'),
          button: i18n.gettext('Descargar'),
          button_url: 'https://bit.ly/ShopsAR_BannersCM',
          track: 'download_banners',
          image: 'mla/banners_descargables.jpg',
        },
      ],
    },
  ],
});
