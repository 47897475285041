import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import { Image } from 'nordic/image';

import WordingsTable from '../WordingsTable';
import Terms from '../terms';
import wordings from '../wordings';
import Arrow from '../../../assets/components/arrow';
import trackEvent from '../../../../../utils/tracking';
import { TRACK_VIEW_VALUE_DETAILS } from './tracks';

const PricesDesktop = ({ i18n, siteId }) => {
  const [collapsed, setCollapsed] = useState(false);

  const myRef = useRef(null);
  const executeScroll = () => {
    setCollapsed(collapsed => {
      const newCollapsed = !collapsed;
      if (!collapsed) {
        myRef.current.scrollIntoView({ behavior: 'smooth' });
      } else {
        trackEvent(TRACK_VIEW_VALUE_DETAILS());
      }
      return newCollapsed;
    });
  };

  const btnClass = ClassNames({
    prices__arrowUp: collapsed,
    prices__arrowDown: !collapsed,
  });

  const containerClass = ClassNames('prices__container',
    { 'prices__table-cont': collapsed, 'prices__table-cont-close': !collapsed },
    { 'prices__table-cont-mla': collapsed && siteId === 'MLA', 'prices__table-cont-close-mla': !collapsed && siteId === 'MLA' });

  const locales = WordingsTable(i18n, siteId);
  const content = wordings(i18n);

  const {
    title,
    description,
    ShowTable,
    HideTable,
  } = content.prices;

  const {
    logo_1,
    logo_1_alt,
    logo_2,
    logo_2_alt,
    logo_3,
    logo_3_alt,
  } = locales.logos;

  return (
    <section id="prices" className="prices">
      <div className="prices__container">
        <div className="prices__title-cont">
          <h2 className="prices__headline" dangerouslySetInnerHTML={{ __html: title }} />
          <p className="prices__paragraph" dangerouslySetInnerHTML={{ __html: description }} />
        </div>
      </div>
      <div className={containerClass}>
        <table width="100%" className="table" ref={myRef}>
          <thead>
            <tr>
              <th className="table__logos-row"> </th>
              <th className="table__logos-row">
                <Image src="landing30/logo_new_mercadoshops.svg" className="table__zimages" alt="Logo Mercado Shops" lazyload="off"/>
              </th>
              <th className="table__logos-row">
                <Image src={logo_1} className="table__images" alt={logo_1_alt} lazyload="off" />
              </th>
              <th className="table__logos-row">
                <Image src={logo_2} className="table__images" alt={logo_2_alt} lazyload="off" />
              </th>
              <th className="table__logos-row">
                <Image src={logo_3} className="table__images" alt={logo_3_alt} lazyload="off" />
              </th>
            </tr>
          </thead>
          <tbody>
            {locales.table.map((item, key) => {
              const isLastRow = (locales.table.length - 1 === key);
              const tableHeaderClasses = ClassNames('table__col-base table__background-th', {
                'table--last-row': isLastRow,
              });
              const tableCellHighlightedClasses = ClassNames('table__col-base table--highlight', {
                'table--top-flap': (key === 0),
                'table--bottom-flap': isLastRow,
              });
              const tableCellClasses = ClassNames('table__col-base table--background-group', {
                'table--last-row': isLastRow,
              });
              return (
                <tr>
                  <th className={tableHeaderClasses}>
                    <p className="table__titles-col" dangerouslySetInnerHTML={{ __html: item.title_col }} />
                    {item.col_1}
                  </th>
                  <td className={tableCellHighlightedClasses}>
                    {item.col_2}
                  </td>
                  <td className={tableCellClasses}>
                    {item.col_3}
                  </td>
                  <td className={tableCellClasses}>
                    {item.col_4}
                  </td>
                  <td className={tableCellClasses}>
                    {item.col_5}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <Terms locales={locales.legales} />
      </div>
      <div className="prices__container prices__container--button-show">
        <a onClick={executeScroll} className="prices__btn-expand"> {collapsed ? HideTable : ShowTable}
          <span className="prices__arrowCont">
            <Arrow className={btnClass} />
          </span>
        </a>
      </div>
    </section>
  );
};

PricesDesktop.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }).isRequired,
};

export default PricesDesktop;
