import React from 'react';

const IconLearnTrafic = () => (
  <svg
    width="30"
    height="32"
    viewBox="0 0 30 32"
    fill="none"
  >
    <path
      d="M8.01203 19.1088H1V31.2008H8.01203V19.1088Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.2572 14.8236H11.2451V31.2007H18.2572V14.8236Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.5306 7.97994H21.5186V31.2008H28.5306V7.97994Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.396 12.5958L19.8493 3.27151"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.4895 5.16847L20.3336 3.08667L19.1777 1L21.5375 1.02918L23.8924 1.05837L22.6933 3.11585L21.4895 5.16847Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconLearnTrafic;
