import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Image } from 'nordic/image';
import classnames from 'classnames';

import WordingsTable from '../WordingsTable';
import wordings from '../wordings';
import Terms from '../terms';
import IconArrowOutline from '../../../assets/components/IconArrowOutline';
import PricingTable from './components/PricingTable';
import trackEvent from '../../../../../utils/tracking';
import constants from './constants';
import { TRACK_VIEW_VALUE_DETAILS } from './tracks';

const PricesMobile = ({ i18n, siteId }) => {
  const locales = WordingsTable(i18n, siteId);
  const content = wordings(i18n);
  const [active, setActive] = useState(false);
  const [shop, setShop] = useState(0);
  const myRef = useRef(null);
  const SHOPS_PRICES = constants(siteId);

  const pricingMobileBtnClass = (id) => classnames('pricingMobile__btn', { 'pricingMobile__btn--active': id === shop });

  const pricingMobileTableClass = classnames('pricingMobile__table', { 'pricingMobile__table--active': active });

  const toggleButton = () => {
    setActive(!active);
    if (active) {
      myRef.current.scrollIntoView({ behavior: 'smooth' });
    } else {
      const { track } = SHOPS_PRICES.find((item) => item.id === shop);
      trackEvent(TRACK_VIEW_VALUE_DETAILS(track));
    }
  };

  const toggleTab = (id) => {
    setShop(id);
    setActive(false);
  };

  const btnClass = classnames({
    arrowUp: active,
  });

  return (
    <div className="pricingMobile">
      <h3 className="pricingMobile__title" ref={myRef} dangerouslySetInnerHTML={{ __html: content.prices.title }} />
      <p className="pricingMobile__description" dangerouslySetInnerHTML={{ __html: content.prices.description }} />
      <div className="pricingMobile__subtitle">{content.prices.subtitle}</div>
      <div className="pricingMobile__btn-group">
        {SHOPS_PRICES.map((item) => (
          <button
            type="button"
            onClick={() => toggleTab(item.id)}
            className={pricingMobileBtnClass(item.id)}
          >
            <Image src={item.img} className="pricingMobile__btn-img" alt={item.alt} lazyload="off" />
          </button>
        ))}
      </div>
      <div className={pricingMobileTableClass}>
        <PricingTable active={active} shop={shop} locales={locales} siteId={siteId} />
        <Terms locales={locales.legales} />
      </div>
      <button
        type="button"
        className="pricingMobile__show-info"
        onClick={() => toggleButton()}
      >
        {!active ? content.prices.ShowTable : content.prices.HideTable}
        <IconArrowOutline className={btnClass} />
      </button>
    </div>
  );
};

PricesMobile.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }).isRequired,
  siteId: PropTypes.string.isRequired,
};

export default PricesMobile;
