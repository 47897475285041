const col_2_3_1 = '$10.499';
const col_2_3_2 = '$114.999';
const col_2_4_1 = 'USD 25';
const col_2_4_2 = 'USD 2.300';
const col_2_5_1 = 'USD 70';

const col_3_3_1 = '0,2%';
const col_3_3_2 = '2%';

const col_4_2_1 = '3,99%';
const col_4_3_1 = '4,39%';

const col_5_2_1 = '7,30%';

const col_6_2_1 = '10,93%';
const col_6_3_1 = '17,64%';

const col_7_2_1 = '15,56%';
const col_7_3_1 = '26,14%';

const col_8_2_1 = '18,87%';
const col_8_3_1 = '34,64%';

const col_9_2_1 = '10,02%';
const col_9_3_1 = '10,42%';

const col_10_2_1 = '15,44%';
const col_10_3_1 = '15,84%';

const col_11_2_1 = '45%';

const col_12_2_1 = '23,82%';
const col_12_3_1 = '41,29%';

const dateInfoLegales = '25/07/2024';

const tags = ['<strong>', '</strong>'];

// i18nLocalesOverride:['pt-AR']
export default i18n => ({
  logos: {
    logo_1: 'landing30/logo_tiendanube.png',
    logo_1_alt: 'Tiendanube',
    logo_2: 'landing30/logo_shopify.png',
    logo_2_alt: 'Shopify',
    logo_3: 'landing30/logo_woo_commerce.png',
    logo_3_alt: 'Woocommerce',
  },
  table: [
    {
      col_1: i18n.gettext('Costos fijos de plataforma'),
      col_2: i18n.gettext('Gratis'),
      col_3: i18n.gettext('{0} a {1} fijo mensual (Tienda Inicial gratis con Pago Nube)', col_2_3_1, col_2_3_2),
      col_4: i18n.gettext('{0} a {1} fijo mensual', col_2_4_1, col_2_4_2),
      col_5: i18n.gettext('A partir de {0} fijo mensual', col_2_5_1),
    }, {
      col_1: i18n.gettext('Costo por transacción de la plataforma'),
      col_2: i18n.gettext('Gratis'),
      col_3: i18n.gettext('{0} a {1} por transacción (gratis con Pago Nube)', col_3_3_1, col_3_3_2),
      col_4: i18n.gettext('{0} a {1} por transacción', col_3_3_1, col_3_3_2),
      col_5: i18n.gettext('Gratis'),
    }, {
      title_col: i18n.gettext('{0}Cargo por vender{1}', ...tags),
      col_2: i18n.gettext('{0} + IVA', col_4_2_1),
      col_3: i18n.gettext('{0} + IVA', col_4_3_1),
      col_4: i18n.gettext('{0} + IVA', col_4_3_1),
      col_5: i18n.gettext('{0} + IVA', col_4_3_1),
    }, {
      title_col: i18n.gettext('Cargo por vender {0}ofreciendo hasta 12 cuotas con interés bajo{1}', ...tags),
      col_1: i18n.gettext('Cuotas compartidas con el comprador'),
      col_2: i18n.gettext('{0} + IVA', col_5_2_1),
      col_3: i18n.gettext('No disponible'),
      col_4: i18n.gettext('No disponible'),
      col_5: i18n.gettext('No disponible'),
    },
    {
      title_col: i18n.gettext('Cargo por vender {0}ofreciendo hasta 3 cuotas al precio publicado{0}', ...tags),
      col_2: i18n.gettext('{0} + IVA', col_6_2_1),
      col_3: i18n.gettext('{0} + IVA', col_6_3_1),
      col_4: i18n.gettext('{0} + IVA', col_6_3_1),
      col_5: i18n.gettext('{0} + IVA', col_6_3_1),
    }, {
      title_col: i18n.gettext('Cargo por vender {0}ofreciendo hasta 6 cuotas al precio publicado{1}', ...tags),
      col_2: i18n.gettext('{0} + IVA', col_7_2_1),
      col_3: i18n.gettext('{0} + IVA', col_7_3_1),
      col_4: i18n.gettext('{0} + IVA', col_7_3_1),
      col_5: i18n.gettext('{0} + IVA', col_7_3_1),
    }, {
      title_col: i18n.gettext('Cargo por vender {0}ofreciendo hasta 9 cuotas al precio publicado{1}', ...tags),
      col_2: i18n.gettext('{0} + IVA', col_8_2_1),
      col_3: i18n.gettext('{0} + IVA', col_8_3_1),
      col_4: i18n.gettext('{0} + IVA', col_8_3_1),
      col_5: i18n.gettext('{0} + IVA', col_8_3_1),
    },
    {
      title_col: i18n.gettext('Cargo por vender {0}ofreciendo hasta 12 cuotas al precio publicado{1}', ...tags),
      col_2: i18n.gettext('{0} + IVA', col_12_2_1),
      col_3: i18n.gettext('{0} + IVA', col_12_3_1),
      col_4: i18n.gettext('{0} + IVA', col_12_3_1),
      col_5: i18n.gettext('{0} + IVA', col_12_3_1),
    },
    {
      title_col: i18n.gettext(
        'Cargo por vender {0}ofreciendo hasta 3 cuotas al precio publicado con Cuota Simple{1}',
        ...tags,
      ),
      col_2: i18n.gettext('{0} + IVA', col_9_2_1),
      col_3: i18n.gettext('{0} + IVA', col_9_3_1),
      col_4: i18n.gettext('{0} + IVA', col_9_3_1),
      col_5: i18n.gettext('{0} + IVA', col_9_3_1),
    }, {
      title_col: i18n.gettext(
        'Cargo por vender {0}ofreciendo hasta 6 cuotas al precio publicado con Cuota Simple{1}',
        ...tags,
      ),
      col_2: i18n.gettext('{0} + IVA', col_10_2_1),
      col_3: i18n.gettext('{0} + IVA', col_10_3_1),
      col_4: i18n.gettext('{0} + IVA', col_10_3_1),
      col_5: i18n.gettext('{0} + IVA', col_10_3_1),
    }, {
      col_1: i18n.gettext('Costo por envío gratis en 24 hs'),
      col_2: i18n.gettext('{0} de descuento en publicaciones con cuotas al precio publicado', col_11_2_1),
      col_3: i18n.gettext('Costo total a cargo del vendedor'),
      col_4: i18n.gettext('Costo total a cargo del vendedor'),
      col_5: i18n.gettext('Costo total a cargo del vendedor'),
    }, {
      col_1: i18n.gettext('Acceso a canal de publicidad exclusivo Mercado Ads'),
      col_2: i18n.gettext('Tráfico desde Mercado Libre a tu Shop'),
      col_3: i18n.gettext('No disponible'),
      col_4: i18n.gettext('No disponible'),
      col_5: i18n.gettext('No disponible'),
    },
  ],
  legales: [
    {
      text: i18n.gettext('(1) Las tarifas incluidas en la fila "Costos fijos plataforma" no contemplan precios promocionales, descuentos por contrataciones trimestrales o semestrales, ni precios de nuevos procesadores de pago y ni pruebas gratuitas. Las tarifas de Shopify y Woocommerce están publicadas en su sitio oficial en dólares estadounidenses, no incluye el valor de los impuestos Percepción IVA Servicios Digitales del Exterior, Percepción Impuesto PAIS, Percepción Ganancias RG4815 y Percepciones Provinciales Servicios Digitales del Exterior.'),
    },
    {
      text: i18n.gettext('(2) Las tarifas incluidas en las filas "Costos fijos de plataforma", "Cargo por vender", "Cargo por vender ofreciendo hasta 3 cuotas al precio publicado", "Cargo por vender ofreciendo hasta 6 cuotas al precio publicado", "Cargo por vender ofreciendo hasta 3 cuotas al precio publicado con Cuota Simple" y "Cargo por vender ofreciendo hasta 6 cuotas al precio publicado con Cuota Simple" corresponden al servicio prestado por Mercado Pago y considerando un plazo de liberación de dinero de 10 días contados a partir de la aprobación del pago.'),
    },
    {
      text: i18n.gettext('(3) En relación a la fila "Costo por envío gratis en 24 horas" se aclara que Tienda Nube, Shopify y WooCommerce no informan servicios de envíos ni beneficios asociados para el vendedor;'),
    },
    {
      text: i18n.gettext('(4) Este cuadro tiene meros fines informativos y ha sido confeccionado con información pública obtenida con fecha {0} de los sitios oficiales de Tienda Nube, Shopify y WooCommerce destinados para la República Argentina.', dateInfoLegales),
    },
    {
      text: i18n.gettext('(5) Las marcas Tienda Nube, Shopify y WooCommerce no son propiedad de Mercado Libre, perteneciendo a sus respectivos titulares.'),
    },
  ],
});
