import React from 'react';

const IconTemplates = () => (
  <svg
    width="54"
    height="45"
    viewBox="0 0 54 45"
  >
    <path
      d="M32.9226 32.7948H15.4438C14.8923 32.7948 14.3633 33.0139 13.9733 33.4039C13.5834 33.7939 13.3643 34.3228 13.3643 34.8744V40.5516C13.3643 41.1031 13.5834 41.6321 13.9733 42.0221C14.3633 42.4121 14.8923 42.6312 15.4438 42.6312H36.8738C37.4253 42.6312 37.9543 42.4121 38.3443 42.0221C38.7343 41.6321 38.9534 41.1031 38.9534 40.5516V34.8744C38.9534 34.3228 38.7343 33.7939 38.3443 33.4039C37.9543 33.0139 37.4253 32.7948 36.8738 32.7948H32.9226Z"
      fill="#FBEDA2"
    />
    <path
      d="M11.2847 34.8744V40.5516C11.2847 41.1031 11.0656 41.6321 10.6756 42.0221C10.2856 42.4121 9.75669 42.6312 9.20515 42.6312H3.90224C3.44811 42.6298 2.99875 42.5384 2.58023 42.3621C2.16171 42.1858 1.78234 41.9282 1.46413 41.6042C1.14593 41.2801 0.895201 40.8962 0.726497 40.4745C0.557794 40.0529 0.474468 39.602 0.481351 39.1479V34.8744C0.481351 34.3228 0.700448 33.7939 1.09044 33.4039C1.48044 33.0139 2.00939 32.7948 2.56092 32.7948H9.20515C9.75669 32.7948 10.2856 33.0139 10.6756 33.4039C11.0656 33.7939 11.2847 34.3228 11.2847 34.8744Z"
      fill="#FBEDA2"
    />
    <path
      d="M36.8531 21.5651H29.2627C28.1142 21.5651 27.1831 22.4962 27.1831 23.6447V28.6357C27.1831 29.7842 28.1142 30.7152 29.2627 30.7152H36.8531C38.0016 30.7152 38.9327 29.7842 38.9327 28.6357V23.6447C38.9327 22.4962 38.0016 21.5651 36.8531 21.5651Z"
      fill="#FBEDA2"
    />
    <path
      d="M43.1128 21.5651C42.5612 21.5651 42.0323 21.7842 41.6423 22.1742C41.2523 22.5642 41.0332 23.0932 41.0332 23.6447V40.5516C41.0332 41.1031 41.2523 41.6321 41.6423 42.0221C42.0323 42.4121 42.5612 42.6312 43.1128 42.6312H49.0188C50.0143 42.6312 50.969 42.2357 51.673 41.5318C52.3769 40.8278 52.7724 39.8731 52.7724 38.8775V23.6447C52.7724 23.0932 52.5533 22.5642 52.1633 22.1742C51.7733 21.7842 51.2443 21.5651 50.6928 21.5651H43.1128Z"
      fill="#FFE600"
    />
    <path
      d="M50.6718 10.5954H43.0918C41.9433 10.5954 41.0122 11.5265 41.0122 12.675V17.406C41.0122 18.5545 41.9433 19.4856 43.0918 19.4856H50.6718C51.8203 19.4856 52.7514 18.5545 52.7514 17.406V12.675C52.7514 11.5265 51.8203 10.5954 50.6718 10.5954Z"
      fill="#FBEDA2"
    />
    <path
      d="M36.8531 10.5954H29.2627C28.1142 10.5954 27.1831 11.5265 27.1831 12.675V17.406C27.1831 18.5545 28.1142 19.4856 29.2627 19.4856H36.8531C38.0016 19.4856 38.9327 18.5545 38.9327 17.406V12.675C38.9327 11.5265 38.0016 10.5954 36.8531 10.5954Z"
      fill="#FBEDA2"
    />
    <path
      d="M19.7175 10.5954H2.56102C2.00948 10.5954 1.48053 10.8145 1.09054 11.2045C0.700542 11.5945 0.481445 12.1234 0.481445 12.675L0.481445 28.6357C0.481445 29.1872 0.700542 29.7162 1.09054 30.1062C1.48053 30.4961 2.00948 30.7152 2.56102 30.7152H23.024C23.5755 30.7152 24.1045 30.4961 24.4945 30.1062C24.8845 29.7162 25.1036 29.1872 25.1036 28.6357V12.675C25.1036 12.1234 24.8845 11.5945 24.4945 11.2045C24.1045 10.8145 23.5755 10.5954 23.024 10.5954H19.7175Z"
      fill="#FFE600"
    />
    <path
      d="M52.5539 3.42089V7.47606C52.5539 7.75182 52.4443 8.0163 52.2493 8.21129C52.0543 8.40629 51.7899 8.51584 51.5141 8.51584H1.52123C1.24546 8.51584 0.980989 8.40629 0.785991 8.21129C0.590994 8.0163 0.481445 7.75182 0.481445 7.47606V3.43129C0.481445 2.52126 0.842955 1.64849 1.48645 1.005C2.12994 0.36151 3.0027 0 3.91274 0L49.133 0C50.0403 0 50.9104 0.360415 51.5519 1.00196C52.1935 1.6435 52.5539 2.51362 52.5539 3.42089Z"
      fill="#E73B90"
    />
  </svg>
);

export default IconTemplates;
