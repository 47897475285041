import React from 'react';

const IconColors = () => (
  <svg
    width="53"
    height="53"
    viewBox="0 0 53 53"
  >
    <g>
      <path
        d="M11.71 23.42C18.1773 23.42 23.42 18.1773 23.42 11.71C23.42 5.24274 18.1773 0 11.71 0C5.24275 0 0 5.24274 0 11.71C0 18.1773 5.24275 23.42 11.71 23.42Z"
        fill="#E73B90"
      />
      <path
        d="M22.3702 34.04C28.8374 34.04 34.0802 28.7972 34.0802 22.33C34.0802 15.8627 28.8374 10.62 22.3702 10.62C15.9029 10.62 10.6602 15.8627 10.6602 22.33C10.6602 28.7972 15.9029 34.04 22.3702 34.04Z"
        fill="#FFE600"
      />
      <path
        d="M40.6202 52.25C47.0874 52.25 52.3302 47.0073 52.3302 40.54C52.3302 34.0728 47.0874 28.83 40.6202 28.83C34.1529 28.83 28.9102 34.0728 28.9102 40.54C28.9102 47.0073 34.1529 52.25 40.6202 52.25Z"
        fill="#FBEDA2"
      />
    </g>
    <defs>
      <clipPath>
        <rect width="52.33" height="52.25" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default IconColors;
