import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';
import Image from 'nordic/image';

import MercadoLibre from '../../assets/components/MercadoLibre';
import MercadoPago from '../../assets/components/MercadoPago';
import MercadoAds from '../../assets/components/MercadoAds';
import MercadoEnvios from '../../assets/components/MercadoEnvios';

const Card = ({
  brand,
  title,
  description,
}) => {
  const LogoSelector = {
    mercadolivre: <Image src="landing30/mlb/logo_mlb.png" className="ecosystems-cards__logo-mlb" alt="" />,
    mercadolibre: <MercadoLibre />,
    mercadoads: <MercadoAds />,
    mercadopago: <MercadoPago />,
    mercadoenvios: <MercadoEnvios />,
  };

  const makeLowerCase = brand.toString().toLowerCase().replace(/\s+/g, '');
  const cardClass = classnames('ecosystems-cards__card-cont',
    { 'meli-border': makeLowerCase !== 'mercadopago' },
    { 'cian-border': makeLowerCase === 'mercadopago' });

  return (
    <div className="ecosystems-cards">
      <div className={cardClass}>
        <div className="ecosystems-cards__logo">
          {LogoSelector[makeLowerCase]}
        </div>
        <div className="ecosystems-cards__content">
          <h3 className="ecosystems-cards__title">
            {title}
          </h3>
          <p
            className="ecosystems-cards__text"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>
      </div>
    </div>
  );
};

Card.propTypes = {
  brand: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default Card;
