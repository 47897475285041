// i18nLocalesExclusion:['pt-BR']

export default i18n => ({
  category_list: [
    {
      title: i18n.gettext('Configuraciones de tu Shop'),
      category: 'config',
      List: [
        {
          /* i18n-next-line except: ["pt-BR"] */
          title: i18n.gettext('Super Guia Mercado Shops'),
          button: i18n.gettext('Descargar'),
          button_url: 'https://bit.ly/ShopsCO_SuperGuia3',
          track: 'mshops_guide',
          image: 'mla/super_guia_mshops.jpg',
        },
        {
          title: i18n.gettext('Glosario digital: no te pierdas en el mundo del ecommerce'),
          button: i18n.gettext('Descargar'),
          button_url: 'https://drive.google.com/file/d/1tp1ciZqtrvZ9fU3Vs_epZrtISiup7odC/view',
          track: 'digital_glossary',
          image: 'mla/glosario_digital.jpg',
        },
      ],
    },
    {
      title: i18n.gettext('Fidelización y ventas'),
      category: 'sales',
      List: [
        {
          title: i18n.gettext('Calendario fechas especiales 2023'),
          button: i18n.gettext('Descargar'),
          button_url: 'https://drive.google.com/file/d/1cETWPY0QtsFyA6EIqnUHBkKrg4mV3VUH/view',
          track: 'ecommerce_calendar',
          image: 'mla/calendario_fechas_especiales.jpg',
        },
      ],
    },
  ],
});
