import React from 'react';

const IconMetrics = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
  >
    <g>
      <path
        d="M34.7801 15.28C36.2801 24.55 29.9901 33.28 20.7201 34.78C11.4501 36.28 2.72014 29.99 1.22014 20.73C-0.279856 11.46 6.01014 2.73005 15.2801 1.23005C24.5401 -0.279954 33.2701 6.01005 34.7801 15.28Z"
        fill="#E73B90"
        stroke="#E43B8D"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M8.16992 24.79C9.41256 24.79 10.4199 23.7827 10.4199 22.54C10.4199 21.2974 9.41256 20.29 8.16992 20.29C6.92728 20.29 5.91992 21.2974 5.91992 22.54C5.91992 23.7827 6.92728 24.79 8.16992 24.79Z"
        fill="white"
      />
      <path
        d="M13.2598 19.0901C14.5024 19.0901 15.5098 18.0827 15.5098 16.8401C15.5098 15.5974 14.5024 14.5901 13.2598 14.5901C12.0171 14.5901 11.0098 15.5974 11.0098 16.8401C11.0098 18.0827 12.0171 19.0901 13.2598 19.0901Z"
        fill="white"
      />
      <path
        d="M19.7798 22.54C21.0224 22.54 22.0298 21.5327 22.0298 20.29C22.0298 19.0474 21.0224 18.04 19.7798 18.04C18.5371 18.04 17.5298 19.0474 17.5298 20.29C17.5298 21.5327 18.5371 22.54 19.7798 22.54Z"
        fill="white"
      />
      <path
        d="M26.2499 18.8701C28.3652 18.8701 30.0799 17.1553 30.0799 15.0401C30.0799 12.9248 28.3652 11.2101 26.2499 11.2101C24.1347 11.2101 22.4199 12.9248 22.4199 15.0401C22.4199 17.1553 24.1347 18.8701 26.2499 18.8701Z"
        fill="white"
      />
      <path
        d="M9.19971 21.1401L11.9797 17.6101"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M14.6699 16.9801L18.2499 19.6701"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M20.77 19.8701L23.76 17.1301"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </g>
    <defs>
      <clipPath>
        <rect width="36" height="36" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default IconMetrics;
