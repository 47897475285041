// i18nLocalesOverride:['pt-AR']

export default {
  tallerVideo: 'https://www.youtube.com/embed/e_6m-W7DYPE',
  tallerCount1Number: '+400',
  tallerCount2Number: '+70%',
  tallerBtnLink: 'https://partners.mercadolibre.com.ar/taller-mercado-shops-ar',

  brigadaVideo: 'https://www.youtube.com/embed/etRhgObo0H0',
  brigadaCount1Number: '+600',
  brigadaCount2Number: 'X7',
  brigadaCount2Subtitle: '7 veces',
  brigadaBtnLink: 'https://partners.mercadolibre.com.ar/brigada-mercado-shops-ar',

  agenciaBtnLink: 'https://centrodepartners.mercadolibre.com.ar/search/marketing-y-publicidad?business_units=mshops&subcategories=marketing-digital&utm_source=mercadoshops&utm_medium=referral&utm_campaign=new_site_agencias_certificadas',
};
