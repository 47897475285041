
import MLA from './mla';
import MLB from './mlb';

export default (i18n, siteId) => {
  const ROCKETS = {
    MLA: MLA(i18n),
    MLM: MLA(i18n),
    MCO: MLA(i18n),
    MLC: MLA(i18n),
    MLB: MLB(i18n),
  };

  return ROCKETS[siteId];
};
