const col_2_3_1 = 'R$0';
const col_2_3_2 = 'R$799';
const col_2_4_1 = 'USD $25';
const col_2_4_2 = 'USD $399';
const col_2_4_3 = 'R$123';
const col_2_4_4 = 'R$1.963';

const col_2_5_1 = 'R$389';

const col_3_3_1 = '0,7%';
const col_3_3_2 = '2%';

const col_4_3_1 = '4,49%';
const col_4_3_2 = '0,99%';
const col_4_3_3 = 'R$3,49';

const col_5_2_1 = '5,99%';
const col_5_3_1 = '9,11%';

const col_6_2_1 = '11,99%';
const col_6_3_1 = '21,77%';

const col_10_2_1 = '50%';
const date_legales = '26/12/2023';
const date_info_competitors = '25/01/2024';

export default i18n => ({
  logos: {
    logo_1: 'landing30/logo_nuvemshop.png',
    logo_1_alt: 'Nubemshop',
    logo_2: 'landing30/logo_shopify.png',
    logo_2_alt: 'Shopify',
    logo_3: 'landing30/logo_loja_integrada.png',
    logo_3_alt: 'Lojaintegrada',
  },
  table: [
    {
      col_1: i18n.gettext('Costos fijos por plataforma'),
      col_2: i18n.gettext('Gratis'),
      col_3: i18n.gettext('{0} a {1} fijo mensual', col_2_3_1, col_2_5_1),
      // eslint-disable-next-line max-len
      col_4: `${i18n.gettext('{0} a {1} fijo mensual', col_2_4_1, col_2_4_2)} ${('(aprox {0} a {1})', col_2_4_3, col_2_4_4)}`,
      col_5: i18n.gettext('Plan Gratis con límite de Tráfico a {0} fijo mensual', col_2_3_2),
    }, {
      col_1: i18n.gettext('Costo por transacción por plataforma'),
      col_2: i18n.gettext('Gratis'),
      /* i18n-next-line only: ["pt-BR"] */
      col_3: `${i18n.gettext('{0} a {1} por transacción', col_3_3_1, col_3_3_2)} ${i18n.gettext('(gratis con NuvemPago)')}`,
      col_4: i18n.gettext('{0} a {1} por transacción', col_3_3_1, col_3_3_2),
      col_5: i18n.gettext('Gratis'),
    }, {
      col_1: i18n.gettext('Costos por pago o pago en cuotas con interés por plataforma'),
      col_2: i18n.gettext('Gratis'),
      col_3: ('1x: {0} PIX: {1} Boleto: {2}', col_4_3_1, col_4_3_2, col_4_3_3),
      col_4: ('1x: {0} PIX: {1} Boleto: {2}', col_4_3_1, col_4_3_2, col_4_3_3),
      /* i18n-next-line only: ["pt-BR"] */
      col_5: ('1x: {0} PIX: {1} Boleto: a partir de {2}', col_4_3_1, col_4_3_2, col_4_3_3),
    }, {
      col_1: i18n.gettext('Costo por financiación de hasta 3 cuotas sin interés por plataforma'),
      col_2: i18n.gettext('{0}', col_5_2_1),
      col_3: (col_5_3_1),
      col_4: (col_5_3_1),
      col_5: (col_5_3_1),
    }, {
      col_1: i18n.gettext('Costo por financiación de hasta 12 cuotas sin interés por plataforma'),
      col_2: i18n.gettext('{0}', col_6_2_1),
      col_3: (col_6_3_1),
      col_4: (col_6_3_1),
      col_5: (col_6_3_1),
    }, {
      col_1: i18n.gettext('Costo por envío gratis en 24 hs'),
      col_2: i18n.gettext('Hasta {0} de descuento en publicaciones premium', col_10_2_1),
      col_3: i18n.gettext('Costo total a cargo del vendedor'),
      col_4: i18n.gettext('Costo total a cargo del vendedor'),
      col_5: i18n.gettext('Costo total a cargo del vendedor'),
    }, {
      col_1: i18n.gettext('Acceso a canal de publicidad exclusivo Mercado Ads'),
      col_2: i18n.gettext('Tráfico desde Mercado Libre a tu Shop'),
      col_3: i18n.gettext('No disponible'),
      col_4: i18n.gettext('No disponible'),
      col_5: i18n.gettext('No disponible'),
    },
  ],
  legales: [
    {
      text: i18n.gettext('(1) Las comisiones incluidas en la línea “Costos fijos de la plataforma” no incluyen precios promocionales, descuentos para contratos trimestrales o semestrales o pruebas gratuitas. La comisión de Shopify se publica en su sitio oficial en dólares estadounidenses, pero se informa aquí en reales en la conversión correspondiente a la tasa de cambio de venta de la nota de dólares estadounidenses informada por el Banco Central de Brasil el {0};', date_legales),
    },
    {
      text: i18n.gettext('(2) Los costos incluidos en las rúbricas “Costo por pago o financiación con intererés por plataforma”, “Costo por financiación de hasta 3 cuotas sin interés por plataforma” y “Costo por financiación de hasta 12 cuotas sin interés por plataforma” corresponde al servicio prestado por Mercado Pago y considerando un plazo de liberación de dinero en hasta 14 días a partir de la homologación del pago;'),
    },
    {
      text: i18n.gettext('(3) En cuanto a la línea “Costo de envío gratis en 24 horas”, se aclara que Nuvemshop, Shopify y Loja Integrada no reportan servicios de envío o beneficios asociados para el vendedor;'),
    },
    {
      text: i18n.gettext('(4) Este cuadro tiene caracter meramente informativo y fue elaborado con información pública obtenida el {0} de los sitios oficiales de Nuvemshop, Shopify y Loja Integrada destinados a Brasil;', date_info_competitors),
    },
    {
      text: i18n.gettext('(5) Las marcas Nuvemshop, Shopify y Loja Integrada no son propiedad de Mercado Libre, perteneciendo a sus respectivos propietarios.'),
    },
  ],
});
