export default (i18n) => ({
  title: i18n.gettext('Conocemos el camino que te lleva a vender más'),
  rockets: [
    {
      title: ('Brigadas'),
      subtitle: i18n.gettext('Crecimiento'),
      description: i18n.gettext('Atención de primera mano con expertos de Marketing Digital e e-commerce, para que te ayuden a llevar tu tienda al siguiente nivel.'),
      month_mlb: i18n.gettext('{0}1°{1} mes', '<strong>', '</strong>'),
    },
    {
      title: i18n.gettext('Mejora contínua'),
      subtitle: i18n.gettext('Consolidación'),
      description: i18n.gettext('Te ayudamos en cada paso que quieras dar, acompañándote junto a nuestros socios con herramientas certificadas que harán más fácil tu trabajo.'),
      month_mlb: i18n.gettext('{0}2°{1} mes', '<strong>', '</strong>'),
      month2_mlb: i18n.gettext('{0}3°{1} mes', '<strong>', '</strong>'),
    },
  ],
});
